import { useMemo } from "react";
import { useQuery } from "react-query";
import APIs from "services";
import {
  ErrorStatus,
  MarketingMaterial,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import {
  Account,
  DeliveryMethod,
  MaterialStatus,
  Template,
  TemplateFile,
} from "shared/types/salesEnablement";
import { QUERY_KEYS } from "../utils/constants";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { useUser } from "shared/hooks/useUser";
import {
  useGetTemplateErrorStatusFn,
  type TemplateErrorStatus,
} from "screens/designStudio/templates/errorStatus.utils";
import { isTruthy } from "utils/helpers.array";
import { useMarketingMaterialErrorStatus } from "./useMarketingMaterialErrorStatus";
import { useFetchAccounts } from "shared/hooks/salesEnablement/useFetchAccounts";
import { useInfiniteFetchAll } from "shared/hooks/useInfiniteFetchAll";
import { AgentFeedInfo } from "shared/types/salesEnablement";
import { getMarketingMaterialValidationErrors } from "../utils/getMarketingMaterialValidation";
import { useFetchAgentFeedInfoByEmail } from "shared/hooks/salesEnablement/useAgentFeedData";
import { useFetchPrintOptions } from "shared/hooks/salesEnablement/useFetchPrintOptions";
import { PrintOptionItem } from "shared/types/printOptions";

export const useFetchMarketingMaterials = () => {
  const isAdmin = useIsSalesEnablementAdmin();
  const user = useUser();

  const {
    templates,
    isTemplateLoading,
    isError: isTemplateError,
  } = useFetchTemplates();

  const { data: printOptions, isLoading: isPrintOptionLoading } =
    useFetchPrintOptions();

  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts();

  const {
    data: materials,
    isLoading: materialsLoading,
    isError: isMaterialsError,
  } = useInfiniteFetchAll<{ items: MarketingMaterial[] }, MarketingMaterial>({
    queryKey: QUERY_KEYS.marketingMaterials,
    queryFn: ({ paginationKey }) =>
      APIs.services.salesEnablement.getMarketingMaterials(paginationKey),
    getDataFromResponse: response => response.items ?? [],
  });

  const filteredMaterials = useMemo(() => {
    if (!isAdmin) return materials;
    return materials?.filter(mm => mm.createdBy === user.sub);
  }, [materials, isAdmin, user.sub]);

  const { getMarketingMaterialErrorStatus } = useMarketingMaterialErrorStatus();
  const getTemplateErrorStatus = useGetTemplateErrorStatusFn();
  const getAgentInfo = useFetchAgentFeedInfoByEmail();

  const mergedMarketingMaterials = useMemo(() => {
    if (!filteredMaterials || !templates) return [];
    return mergeMaterialsAndTemplates(
      filteredMaterials,
      templates,
      getTemplateErrorStatus,
      getMarketingMaterialErrorStatus,
      accounts ?? [],
      printOptions ?? [],
    );
  }, [
    filteredMaterials,
    templates,
    getTemplateErrorStatus,
    getMarketingMaterialErrorStatus,
    accounts,
    printOptions,
  ]);

  const {
    data: marketingMaterialsWithStatus = [],
    isLoading: isLoadingStatus,
    isFetching: isFetchingStatus,
    isError: isStatusError,
  } = useQuery(
    ["marketingMaterialsWithStatus", mergedMarketingMaterials],
    async () =>
      await addComputedStatus(
        mergedMarketingMaterials,
        templates ?? [],
        getAgentInfo,
      ),
    {
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return {
    isLoading:
      isTemplateLoading ||
      materialsLoading ||
      isLoadingAccounts ||
      isPrintOptionLoading ||
      isLoadingStatus ||
      isFetchingStatus,
    marketingMaterials: marketingMaterialsWithStatus,
    isError: isMaterialsError || isTemplateError || isStatusError,
  };
};

const mergeMaterialsAndTemplates = (
  materials: MarketingMaterial[],
  templates: Template[],
  getTemplateErrorStatus: (
    template: Template,
  ) => TemplateErrorStatus | undefined,
  getMarketingMaterialErrorStatus: (
    template: Template,
    material: MarketingMaterial,
  ) => ErrorStatus | undefined,
  accounts: Account[],
  printOptions: PrintOptionItem[],
): MarketingMaterialTableItem[] => {
  const templatesById = templates.reduce<Record<string, Template>>(
    (acc, template) => {
      acc[template.id] = template;
      return acc;
    },
    {},
  );

  return materials
    .filter(
      material => material.templateId && templatesById[material.templateId],
    )
    .map(material => {
      const template = templatesById[material.templateId];
      const mergedMaterial = mergeTemplateInMarketingMaterial(
        material,
        template,
        getTemplateErrorStatus,
        getMarketingMaterialErrorStatus,
      );
      return {
        ...mergedMaterial,
        ...getFilterFields(mergedMaterial, accounts),
        printOption: printOptions.find(
          printOption => printOption.id === template.printOptionId,
        ),
      };
    });
};

const computeStatus = async (
  material: MarketingMaterialTableItem,
  template: Template,
  getAgentInfo: (agentId: string) => Promise<AgentFeedInfo>,
  agentInfoCache: Record<string, AgentFeedInfo>,
): Promise<MaterialStatus> => {
  const { isValid } = await getMarketingMaterialValidationErrors({
    material,
    template,
    getAgentInfo: async (agentId: string) => {
      if (agentInfoCache[agentId]) {
        return agentInfoCache[agentId];
      }
      const agentInfo = await getAgentInfo(agentId);
      agentInfoCache[agentId] = agentInfo;
      return agentInfo;
    },
    materialErrorStatus: material.materialErrorStatus,
  });
  return isValid ? MaterialStatus.READY : MaterialStatus.DRAFT;
};

const addComputedStatus = async (
  materials: MarketingMaterialTableItem[],
  templates: Template[],
  getAgentInfo: (agentId: string) => Promise<AgentFeedInfo>,
): Promise<MarketingMaterialTableItem[]> => {
  const templatesById = templates.reduce<Record<string, Template>>(
    (acc, template) => {
      acc[template.id] = template;
      return acc;
    },
    {},
  );

  const agentInfoCache: Record<string, AgentFeedInfo> = {};

  return Promise.all(
    materials.map(async material => {
      const template = templatesById[material.templateId];
      if (!template) {
        return { ...material, materialStatus: MaterialStatus.ERROR };
      }

      const status = await computeStatus(
        material,
        template,
        getAgentInfo,
        agentInfoCache,
      );
      return { ...material, materialStatus: status };
    }),
  );
};

const mergeTemplateInMarketingMaterial = (
  material: MarketingMaterial,
  template: Template,
  getTemplateErrorStatus: (
    template: Template,
  ) => TemplateErrorStatus | undefined,
  getMarketingMaterialErrorStatus: (
    template: Template,
    material: MarketingMaterial,
  ) => ErrorStatus | undefined,
): MarketingMaterialTableItem => {
  const templateFiles = template.files ?? {};
  const templateFilesValues = Object.values(templateFiles) as (
    | TemplateFile
    | undefined
  )[];
  const firstTemplateFile = templateFilesValues[0];
  const templateThumbnail = firstTemplateFile?.thumbnail;
  const templateErrorStatus = getTemplateErrorStatus(template);
  const materialErrorStatus = getMarketingMaterialErrorStatus(
    template,
    material,
  );

  return {
    ...material,
    materialErrorStatus,
    templateId: template.id,
    templateName: template.name,
    templateDescription: template.description,
    templateCreatedAt: template.createdAt,
    templateUpdatedAt: template.lastUpdatedAt,
    templateDeliveryMethods: getDeliveryMethods(template),
    templateAudience: template.audience,
    templateExpirationDate: template.expirationDate,
    templateTags: template.tags,
    templateStatus: template.status,
    templateLocations: template.locations,
    templateThumbnail,
    templateErrorStatus,
    templateCustomizable: template.customizable,
    templateMaterialType: template.materialType,
  };
};

export const getDeliveryMethods = (template: Template): DeliveryMethod[] => {
  return Object.values(template.files ?? {})
    .flatMap(file => (file as TemplateFile | undefined)?.deliveryMethods ?? [])
    .filter(isTruthy);
};

export const getFilterFields = (
  material: MarketingMaterialTableItem,
  accounts: Account[],
): {
  account?: string;
  products?: string[];
} => {
  const result: { account?: string; products?: string[] } = {};

  Object.values(material.fields ?? {}).forEach(field => {
    if (field && typeof field !== "string" && "type" in field) {
      if (field.type === "account") {
        result.account = accounts.find(acc => acc.id === field.value)?.name;
      } else if (field.type === "product") {
        result.products = (result.products ?? []).concat(
          field.productsData.map(data => data.name ?? ""),
        );
      }
    }
  });

  return result;
};

export const getMaterialFromMaterialTableItem = (
  material: MarketingMaterialTableItem,
): MarketingMaterial => {
  return {
    createdAt: material.createdAt,
    createdBy: material.createdBy,
    updatedAt: material.updatedAt,
    id: material.id,
    name: material.name,
    templateId: material.templateId,
    language: material.language,
    templateThumbnail: material.templateThumbnail,
    locations: material.locations,
    fields: material.fields,
    productTypeOffer: material.productTypeOffer,
  };
};
