import { CheckCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Outlet } from "react-router-dom";
import { PrintCheckout } from "screens/adLibrary/marketingMaterials/printDrawer/PrintCheckout";
import { setupEditableCells } from "shared/components/dataList/setupEditableCells";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { useFetchPrintOptions } from "shared/hooks/salesEnablement/useFetchPrintOptions";
import { useFetchPrintOrders } from "shared/hooks/salesEnablement/useFetchPrintOrders";
import { useUpdateArchiveOrderStatus } from "shared/hooks/salesEnablement/useUpdateArchiveOrderStatus";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { useWindowSize } from "shared/hooks/useWindowSize";
import {
  MarketingMaterialPrintDeliveryOrder,
  PrintOrderTableKeys,
} from "shared/types/salesEnablement/order";
import { UpdateArchiveOrderStatusPayload } from "shared/types/salesEnablementArchive";
import styles from "./Archive.module.scss";
import { ROUTES } from "./archive/constants";
import { getColumns, PrintArchiveColumn } from "./archive/printArchive/columns";
import {
  printArchiveFieldKeys,
  printArchiveFields,
} from "./archive/printArchive/fields";
import { DataProvider } from "./hooks/SalesEnablementDataProvider";

const PrintArchiveTableContainer = () => {
  const [form] = useForm();
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();

  const { mutate: updateArchiveOrderStatus } = useUpdateArchiveOrderStatus();

  const { data: printOrders = [], isLoading } = useFetchPrintOrders();

  const { data: printOptions = [] } = useFetchPrintOptions();

  const navigate = useNavigateWithSearch();

  const openOrderDetails = async (
    order: MarketingMaterialPrintDeliveryOrder,
  ) => {
    const updatedFormData = {
      ...order,
      materials: order.deliveryItems.reduce((acc, item) => {
        return {
          ...acc,
          [item.materialId]: {
            ...item,
            printOption: printOptions.find(
              option => option.id === item.printOption.id,
            ),
            templateThumbnail: item.thumbnail,
          },
        };
      }, {}),
      totalAmount: order.totalAmount ?? 0,
    };
    navigate(ROUTES.printOrderDetails(order.id));
    form.setFieldsValue(updatedFormData);
  };

  const handleSave = (record: MarketingMaterialPrintDeliveryOrder) => {
    const { id, orderStatus } = record;
    updateArchiveOrderStatus([
      {
        id,
        orderStatus: orderStatus ?? "IN_PROGRESS",
        archiveIds: record.deliveryItems.flatMap(item => item.id ?? []),
      },
    ]);
  };

  const { columns, components } = setupEditableCells<
    MarketingMaterialPrintDeliveryOrder,
    PrintArchiveColumn
  >({
    columns: getColumns(openOrderDetails),
    handleSave,
  });

  const { data, selectedItems } = useDataListURLData<
    PrintOrderTableKeys,
    MarketingMaterialPrintDeliveryOrder
  >({
    isError: false,
    isLoading,
    data: printOrders,
  });

  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    PrintOrderTableKeys,
    MarketingMaterialPrintDeliveryOrder
  >();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  const generateToolbarContents: (
    record?: MarketingMaterialPrintDeliveryOrder,
  ) => ToolbarButton = record => {
    if (!record) return {};
    return {
      CustomButton: {
        icon: <CheckCircleOutlined />,
        onClick: () => {
          handleSave({ ...record, orderStatus: "COMPLETE" });
        },
      },
    };
  };

  const toolbarContents: ToolbarButton = {
    CustomButton: {
      disabled: selectedItems.length === 0,
      icon: <CheckCircleOutlined />,
      extraInfo: {
        text: "Mark as Complete",
        tooltipText: "Mark as Complete",
        icon: <CheckCircleOutlined />,
      },
      onClick: () => {
        const archives: UpdateArchiveOrderStatusPayload[] = selectedItems.map(
          ({ id, deliveryItems }) => ({
            id,
            orderStatus: "COMPLETE",
            archiveIds: deliveryItems.flatMap(archive => archive.id ?? []),
          }),
        );
        updateArchiveOrderStatus(archives);
      },
    },
  };

  return (
    <div className={styles.container}>
      <ToolbarTable
        titleTooltip="Search"
        toolbarContents={toolbarContents}
        searchPlaceholder="Search"
        onSearch={value => setGlobalFilter(value.trim())}
        searchValue={globalFilter ?? undefined}
        disableSearch={false}
        previewFormat={false}
        sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
        onSortChange={([columnKey, order]) => {
          sortItems(columnKey, order);
        }}
        includeFilterOnTableLayout={true}
      />
      <DataListTagsURL<PrintOrderTableKeys, MarketingMaterialPrintDeliveryOrder>
        data={data}
        originalData={printOrders}
      />
      <TableActionsContextProvider<MarketingMaterialPrintDeliveryOrder>
        renderContextActions={(
          record?: MarketingMaterialPrintDeliveryOrder,
        ) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<
          PrintOrderTableKeys,
          MarketingMaterialPrintDeliveryOrder
        >
          type="hoverActionsVirtualTable"
          loading={isLoading}
          dataSource={data}
          components={components}
          columns={columns}
          pagination={false}
          rowKey="id"
          size="small"
          scroll={{ y: windowInnerHeight - 265, x: windowInnerWidth - 20 }}
          fieldKeys={printArchiveFieldKeys}
        />
        <Outlet />

        <Form form={form} layout="horizontal" labelAlign="left">
          <PrintCheckout form={form} />
        </Form>
      </TableActionsContextProvider>
    </div>
  );
};
const PrintArchive = () => {
  return (
    <DataProvider>
      <DataListURLProvider fields={printArchiveFields}>
        <PrintArchiveTableContainer />
      </DataListURLProvider>
    </DataProvider>
  );
};

export default PrintArchive;
