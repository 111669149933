import { FileImageTwoTone } from "@ant-design/icons";
import { CAMSelect } from "shared/components/media";
import { IBox } from "../CardToggle";
import { Button, Dropdown, Menu } from "antd";
import { useMediaSelectPayload } from "./useMediaSelectPayload";

type Props = {
  box: IBox;
  onImageChange?: (box: IBox, imgUrl: string) => void;
};

export const MediaSelect = ({ box, onImageChange }: Props) => {
  const { oemPayload, storePayload, campaignPayload } =
    useMediaSelectPayload(box);

  const onClose = ([{ hiResURLRaw }]: WDAsset[]) => {
    onImageChange?.(box, hiResURLRaw);
  };

  const menuItems = (
    <Menu>
      <Menu.Item key="oem-event-logos">
        <CAMSelect
          width="50vw"
          maxSelected={1}
          title="Select Logo"
          layout="grid"
          search={oemPayload}
          onClose={onClose}
        >
          <Button type="link">OEM Logos</Button>
        </CAMSelect>
      </Menu.Item>
      <Menu.Item key="store-logos">
        <CAMSelect
          width="50vw"
          maxSelected={1}
          title="Select Logo"
          layout="grid"
          search={storePayload}
          onClose={onClose}
        >
          <Button type="link">Store Logos</Button>
        </CAMSelect>
      </Menu.Item>
      <Menu.Item key="campaign-logos">
        <CAMSelect
          width="50vw"
          maxSelected={1}
          title="Select Logo"
          layout="grid"
          search={campaignPayload}
          onClose={onClose}
        >
          <Button type="link">Campaign Logos</Button>
        </CAMSelect>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuItems} trigger={["click"]}>
      <FileImageTwoTone twoToneColor="#1890ff" />
    </Dropdown>
  );
};
