import { message } from "antd";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import API from "services";
import { getResultIfOk } from "services/adLibrary";
import { Invoice } from "shared/types/salesEnablement";
import { longAlert } from "utils/antd/longAlert/longAlert";
import { openInvoiceRegenerationProgress } from "./utils";

const fetchInvoices = async (): Promise<Invoice[]> => {
  const { result } = getResultIfOk(
    await API.services.salesEnablement.getInvoices(),
  );
  return result;
};

const fetchInvoiceById = async (id: string): Promise<Invoice> => {
  const { result } = getResultIfOk(
    await API.services.salesEnablement.getInvoiceById(id),
  );
  return result;
};

const emailInvoiceById = async (invoices: Invoice[], emailIds: string[]) => {
  const payload = {
    invoices,
    emailIds,
  };
  const { result } = getResultIfOk(
    await API.services.salesEnablement.emailInvoices(payload),
  );
  return result;
};

const regenerateInvoice = async (invoice: Invoice) => {
  const { result } = getResultIfOk(
    await API.services.salesEnablement.regenerateInvoice(invoice),
  );
  return result;
};

export const useInvoice = () => {
  const queryClient = useQueryClient();

  const useFetchInvoices = (options: UseQueryOptions<Invoice[], Error> = {}) =>
    useQuery<Invoice[], Error>({
      queryKey: ["invoices"],
      queryFn: fetchInvoices,
      staleTime: 5 * 60 * 1000,
      ...options,
    });

  const useFetchInvoiceById = (
    id: string,
    options: UseQueryOptions<Invoice, Error> = {},
  ) =>
    useQuery<Invoice, Error>({
      queryKey: ["invoice", id],
      queryFn: () => fetchInvoiceById(id),
      enabled: !!id,
      ...options,
    });

  const useEmailInvoice = () =>
    useMutation(
      (payload: { invoices: Invoice[]; emailIds: string[] }) =>
        emailInvoiceById(payload.invoices, payload.emailIds),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["invoices"]);
        },
        onError: () => {
          message.error("Failed to email invoices");
        },
      },
    );

  const useRegenerateInvoice = () =>
    useMutation(
      async (invoice: Invoice) => {
        openInvoiceRegenerationProgress(0, "in-progress");
        return regenerateInvoice(invoice);
      },
      {
        onSuccess: () => {
          openInvoiceRegenerationProgress(100, "success");
          longAlert({
            type: "success",
            header: "Invoice has been successfully generated.",
          });
        },
        onError: () => {
          openInvoiceRegenerationProgress(100, "failed");
          longAlert({
            type: "error",
            header: "Invoice generation has failed. Please try again.",
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries(["invoices"]);
        },
      },
    );

  return {
    useFetchInvoices,
    useFetchInvoiceById,
    useEmailInvoice,
    useRegenerateInvoice,
  };
};
