import { useIsAdmin } from "./useIsAdmin";
import { useHasSalesEnablementReadAccess } from "./useHasSalesEnablementReadAccess";
import { useIsAccountPayableUser } from "./useIsAccountPayableUser";

export const useHasAccountPayableAccess = () => {
  const isAdmin = useIsAdmin();
  const hasSalesEnablementReadAccess = useHasSalesEnablementReadAccess();
  const isAccountPayableUser = useIsAccountPayableUser();

  const hasAccountPayableAccess = isAdmin || isAccountPayableUser;

  const onlyAccountPayableAccess =
    hasAccountPayableAccess && !isAdmin && !hasSalesEnablementReadAccess;

  return {
    hasAccountPayableAccess,
    onlyAccountPayableAccess,
  };
};
