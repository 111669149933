import { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { returnAdAccountsWithoutRepeatNames } from "screens/adLibrary/adLoad/utils";
import { adAccount } from "screens/adLibrary/facebookUtils/adAccount";
import {
  IFacebookAccount,
  IFacebookGetResponseData,
} from "screens/adLibrary/facebookUtils/types";

const useInfiniteFetchFacebookGenericAccounts = (
  accountType: "owned" | "client",
): {
  result: IFacebookAccount[];
  isLoading: boolean;
  error: Error | undefined;
} => {
  const {
    data: result,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["facebookAdAccounts", accountType],
    queryFn: ({ pageParam: nextCursor }) =>
      adAccount.getAdAccounts(accountType, nextCursor),
    getNextPageParam: (lastPage: IFacebookGetResponseData<IFacebookAccount>) =>
      lastPage.paging?.cursors.after,
    staleTime: Infinity,
    cacheTime: 60000,
  });

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const loadingAllPages = isFetchingNextPage || isLoading || !!hasNextPage;

  const pagedResult = useMemo(() => {
    if (loadingAllPages || !result) return [];
    return result?.pages.flatMap(x =>
      x.data.filter(acc => acc.account_status === 1),
    );
  }, [loadingAllPages, result]);

  return {
    result: pagedResult,
    isLoading: loadingAllPages,
    error: isError ? new Error("Error fetching ad accounts") : undefined,
  };
};

export const useFetchFacebookAccounts = () => {
  const {
    result: ownedResult,
    isLoading: ownedIsLoading,
    error: ownedError,
  } = useInfiniteFetchFacebookGenericAccounts("owned");
  const {
    result: clientResult,
    isLoading: clientIsLoading,
    error: clientError,
  } = useInfiniteFetchFacebookGenericAccounts("client");

  const allLoaded = !ownedIsLoading && !clientIsLoading;

  const adAccounts = useMemo(() => {
    if (allLoaded) {
      return returnAdAccountsWithoutRepeatNames([
        ...clientResult,
        ...ownedResult,
      ]);
    }
    return [];
  }, [allLoaded, clientResult, ownedResult]);

  return {
    result: adAccounts,
    isLoading: ownedIsLoading || clientIsLoading,
    error: ownedError || clientError,
  };
};
