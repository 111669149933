import { Col, Form, InputNumber, Row, Typography } from "antd";
import { PrintOptionsRangeQuantity } from "shared/types/printOptions";
import styles from "./MinMaxInput.module.scss";
import { useMemo } from "react";

type Props = {
  fieldName: (string | number)[];
  value?: PrintOptionsRangeQuantity;
  minThreshold?: number;
  maxThreshold?: number;
  allowEqualBounds?: boolean;
};

export const MinMaxInput = ({
  value: quantity,
  fieldName,
  minThreshold,
  maxThreshold,
  allowEqualBounds = false,
}: Props) => {
  const isMinValid = useMemo(() => {
    const { min = 0, max = 0 } = quantity || {};
    if (minThreshold && min !== minThreshold + 1) {
      return false;
    }
    if (max) {
      if (allowEqualBounds) {
        return min <= max;
      }
      return min < max;
    }
    return true;
  }, [quantity, minThreshold, allowEqualBounds]);

  const isMaxValid = useMemo(() => {
    const { max, min } = quantity || {};
    if (maxThreshold) {
      if (max !== maxThreshold - 1) {
        return false;
      }
    }
    if (min && max) {
      if (allowEqualBounds) {
        return max >= min;
      }
      return max > min;
    }
    return true;
  }, [quantity, maxThreshold, allowEqualBounds]);

  return (
    <Row align="middle" justify="space-between" gutter={8}>
      <Col span={11}>
        <Form.Item
          className={styles.formItem}
          name={[...fieldName, "min"]}
          validateStatus={isMinValid ? "success" : "error"}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject();
                }
                return isMinValid ? Promise.resolve() : Promise.reject();
              },
            },
          ]}
        >
          <InputNumber placeholder="Min" min={0} />
        </Form.Item>
      </Col>
      <Typography.Text>to</Typography.Text>
      <Col span={11}>
        <Form.Item
          className={styles.formItem}
          name={[...fieldName, "max"]}
          dependencies={["costPerCopy", ...fieldName, "min"]}
          validateStatus={isMaxValid ? "success" : "error"}
          rules={[
            {
              validator: () => {
                return isMaxValid ? Promise.resolve() : Promise.reject();
              },
            },
          ]}
        >
          <InputNumber placeholder="Max" />
        </Form.Item>
      </Col>
    </Row>
  );
};
