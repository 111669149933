import { Modal, notification } from "antd";
import { queryClient } from "queryClient";
import {
  useCreateMarketingMaterial,
  useCreateMarketingMaterialDelivery,
  useDeleteMarketingMaterial,
  useUpdateMarketingMaterial,
  type MutationMode,
} from "shared/hooks/adLibrary/marketingMaterials";
import { MarketingMaterial } from "shared/types/marketingMaterials";
import { raise } from "utils/errorMessage";
import { QUERY_KEYS } from "../utils/constants";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { pluralize } from "utils/terms";
import uuid from "uuid";
import { salesEnablementTheme } from "theme/salesEnablement";

export const useMarketingMaterialActions = () => {
  const { mutate: create, isLoading: isCreating } =
    useCreateMarketingMaterial();
  const { mutate: createOptimistically } = useCreateMarketingMaterial({
    mode: "optimistic",
  });
  const { mutateAsync: update, isLoading: isUpdating } =
    useUpdateMarketingMaterial();

  const { mutate: deleteMaterials, isLoading: isDeleting } =
    useDeleteMarketingMaterial();

  const { mutate: deleteMaterialsOptimistically } = useDeleteMarketingMaterial({
    mode: "optimistic",
  });

  const { mutateAsync: deliverMaterials } =
    useCreateMarketingMaterialDelivery();

  const onCreate = ({
    values,
    templateId,
    onSuccess,
    mode,
  }: {
    values: Partial<MarketingMaterial>;
    templateId: string;
    onSuccess: (material: MarketingMaterial) => void;
    mode?: MutationMode;
  }): void => {
    const now = new Date().toISOString();

    const { id, name, fields, locations, language, productTypeOffer } = values;

    const newMarketingMaterial: MarketingMaterial = {
      name: name ?? raise("Name is required"),
      id: id ?? raise("Id is required"),
      language: language || "en",
      createdAt: now,
      updatedAt: now,
      templateId,
      locations: locations || [],
      fields,
      productTypeOffer,
    };

    const createFn = mode === "optimistic" ? createOptimistically : create;

    createFn(newMarketingMaterial, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_KEYS.marketingMaterials);
        onSuccess(newMarketingMaterial);
      },

      onError(error) {
        errorNotification({
          messageLabel:
            error?.message ||
            "Something went wrong creating the marketing material.",
        });
      },
    });
  };

  const onUpdate = async (
    values: Partial<MarketingMaterial>,
    material: MarketingMaterial,
  ) => {
    const now = new Date().toISOString();

    const { name, locations, fields, language } = values;

    const updatedMaterial: MarketingMaterial = {
      ...values,
      name: name ?? raise("Name is required"),
      locations: locations || [],
      id:
        material.id || raise("The Marketing Material id is required to update"),
      language: language || "en",
      templateId: material.templateId,
      createdAt: material.createdAt,
      createdBy: material.createdBy,
      updatedAt: now,
      fields,
    };

    try {
      const res = await update(updatedMaterial);
      return res;
    } catch (error) {
      notification.error({
        message: "Error",
        description:
          (error as any)?.message ||
          "Something went wrong editing the marketing material.",
      });
    }
  };

  const onDelete = ({
    ids,
    onSuccess,
    mode,
  }: {
    ids: string[];
    onSuccess?: () => void;
    mode?: MutationMode;
  }) => {
    const deleteFn =
      mode === "optimistic" ? deleteMaterialsOptimistically : deleteMaterials;
    const materialWord = pluralize("material", ids.length);

    const onOk = () => {
      deleteFn(ids, {
        onSuccess() {
          successNotification({
            messageLabel: `The marketing ${materialWord} ${pluralize(
              "has",
              ids.length,
              "have",
            )} been successfully deleted.`,
          });
          onSuccess?.();
        },
        onError(error) {
          errorNotification({
            messageLabel:
              error?.message ||
              `Something went wrong deleting the marketing ${materialWord}.`,
          });
        },
      });
    };
    Modal.confirm({
      title: (
        <b>
          Are you sure you want to permanently delete{" "}
          {pluralize("this", ids.length, "these")} marketing {materialWord}?
        </b>
      ),
      okText: "Delete",
      icon: (
        <ExclamationCircleOutlined
          style={{ color: salesEnablementTheme?.primaryColor }}
        />
      ),
      okButtonProps: { type: "primary", danger: true },
      onOk,
    });
  };

  const onDuplicate = ({
    material,
    onSuccess,
    mode,
  }: {
    material: MarketingMaterial;
    onSuccess?: (material: MarketingMaterial) => void;
    mode?: MutationMode;
  }) => {
    const newMaterial = {
      ...material,
      id: uuid.v4(),
      name: `Copy of ${material.name}`,
    };
    onCreate({
      values: newMaterial,
      templateId: newMaterial.templateId,
      onSuccess: (createdMaterial: MarketingMaterial) => {
        successNotification({
          messageLabel:
            "The marketing material has been successfully duplicated.",
        });
        onSuccess?.(createdMaterial);
      },
      mode,
    });
  };

  return {
    onCreate,
    isCreating,
    onUpdate,
    isUpdating,
    onDelete,
    isDeleting,
    onDuplicate,
    deliverMaterials,
    isSaving: isCreating || isUpdating,
  };
};
