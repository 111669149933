import { Row, Typography, Form, Radio, Skeleton, Alert } from "antd";
import { useCallback } from "react";
import { FedexShippingMethod } from "shared/types/marketingMaterials";
import styles from "./FedexShippingMethods.module.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { differenceInBusinessDays } from "date-fns";

export const FedexShippingMethods = ({
  shippingMethods,
  isLoading,
}: {
  shippingMethods?: FedexShippingMethod[];
  isLoading?: boolean;
}) => {
  const getReadableDeliveryDate = useCallback((deliveryDate: string) => {
    const businessDaysDifference = differenceInBusinessDays(
      new Date(deliveryDate),
      new Date(),
    );
    return `(${businessDaysDifference} business days)`;
  }, []);

  if (!shippingMethods && !isLoading) return null;

  return (
    <Row className={styles.shippingRow}>
      {isLoading && <Skeleton active />}
      {!!shippingMethods && !isLoading && (
        <>
          <Typography.Title level={4}>Shipping Method</Typography.Title>
          <Form.Item name={["shippingData", "shippingMethod"]}>
            <Radio.Group>
              {shippingMethods.map(method => (
                <Radio
                  key={method.serviceType}
                  value={method}
                  className={styles.radio}
                >
                  <Typography.Text strong>
                    ${method.totalCharge}
                  </Typography.Text>
                  {` ${method.serviceName} ${getReadableDeliveryDate(
                    method.deliveryDate,
                  )}`}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Alert
            message="Delivery pricing and time are estimated. The final values will be provided by Communicorp upon the creation of the shipping label."
            type="info"
            showIcon
            icon={<InfoCircleFilled className={styles.infoIcon} />}
          />
        </>
      )}
    </Row>
  );
};
