import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { renderableHtmlVariables } from "screens/designStudio/hooks/useHTMLData";
import { useLayersWithData } from "screens/designStudio/hooks/useLayersWithData";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { getAllAdIds } from "shared/components/dataListURL/utils";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import {
  EmailDeliveryInput,
  MarketingMaterial,
  MarketingMaterialTableItem,
  PrintDeliveryInputMaterialItem,
} from "shared/types/marketingMaterials";
import { TemplateFile } from "shared/types/salesEnablement";
import { raise } from "utils/errorMessage";
import { isTruthy } from "utils/helpers.array";
import { useFetchMarketingMaterials } from "./useFetchMarketingMaterials";

export type MarketingMaterialDeliveryData =
  | EmailDeliveryInput
  | PrintDeliveryInputMaterialItem;

export function useMarketingMaterialDeliveryData(
  deliveryMethod: "email",
): EmailDeliveryInput[];
export function useMarketingMaterialDeliveryData(
  deliveryMethod: "print",
): PrintDeliveryInputMaterialItem[];
export function useMarketingMaterialDeliveryData(
  deliveryMethod: MarketingMaterialDeliveryData["deliveryMethod"],
): MarketingMaterialDeliveryData[] {
  const { marketingMaterials } = useFetchMarketingMaterials();
  const { templates } = useFetchTemplates();

  const { selectedIds: selectedMarketingMaterialsIds } =
    useDataListURLIds<MarketingMaterialTableItem>(getAllAdIds);

  const { materialId } = useParams<{
    materialId: string;
  }>();

  const { containerMaterialId } = useParams<{
    containerMaterialId: string;
  }>();

  const { getLayersWithData } = useLayersWithData();

  const getLayerData = useCallback(
    ({
      file,
      material,
    }: {
      file?: TemplateFile;
      material?: Partial<MarketingMaterial>;
    }) => {
      const pageLayers = file?.pages
        ?.flatMap(page => page.layers)
        .filter(isTruthy);

      if (!pageLayers) return {};

      const layersWithData = getLayersWithData({
        layers: pageLayers,
        material,
      });

      return deliveryMethod === "email"
        ? renderableHtmlVariables(layersWithData, pageLayers)
        : layersWithData;
    },
    [deliveryMethod, getLayersWithData],
  );

  const selectedOtherMarketingMaterials =
    containerMaterialId &&
    !selectedMarketingMaterialsIds?.includes(containerMaterialId);

  const selectedMarketingMaterials = useMemo(() => {
    if (materialId)
      return marketingMaterials.filter(material => material.id === materialId);
    if (selectedOtherMarketingMaterials || containerMaterialId) {
      return marketingMaterials.filter(
        material => material.id === containerMaterialId,
      );
    }
    return marketingMaterials.filter(material =>
      selectedMarketingMaterialsIds?.includes(material.id),
    );
  }, [
    marketingMaterials,
    selectedMarketingMaterialsIds,
    selectedOtherMarketingMaterials,
    containerMaterialId,
    materialId,
  ]);

  return useMemo(() => {
    return selectedMarketingMaterials.map(material => {
      const marketingMaterialTemplate = templates.find(
        template => template.id === material.templateId,
      );
      const templateFile = marketingMaterialTemplate?.files[material.language];
      const layerData = getLayerData({
        file: templateFile,
        material,
      });

      if (deliveryMethod === "email") {
        return {
          material,
          template:
            marketingMaterialTemplate ?? raise("No template file found"),
          deliveryMethod,
          renderVariables: layerData,
        } as EmailDeliveryInput;
      }
      return {
        material,
        template: marketingMaterialTemplate ?? raise("No template file found"),
        deliveryMethod,
        renderVariables: layerData,
      } as PrintDeliveryInputMaterialItem;
    });
  }, [deliveryMethod, getLayerData, selectedMarketingMaterials, templates]);
}
