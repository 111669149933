import AntLayout from "antd/lib/layout";
import { configMenus } from "configMenus";
import { useCallback, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import actions from "redux/rootActions";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useUser } from "shared/hooks/useUser";
import * as helpers from "utils/helpers";
import styles from "./Layout.module.scss";
import Header from "./layout/Header";
import Menu from "./layout/Menu";
import Patent from "./layout/Patent";
import Title from "./layout/Title";
import { useHasBrochureAccess } from "shared/hooks/useHasBrochureAccess";
import { useHasAccountPayableAccess } from "shared/hooks/useHasAccountPayableAccess";
import { useHasPrintArchiveAccess } from "shared/hooks/useHasPrintArchiveAccess";

const Layout = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const [collapsed, setCollapsed] = useState(true);
  const { framed } = useAppSelector(state => state.settings);
  const { onlyBrochureAccess } = useHasBrochureAccess();
  const { onlyAccountPayableAccess } = useHasAccountPayableAccess();
  const { onlyPrintArchiveAccess } = useHasPrintArchiveAccess();
  const menus = useMemo(
    () =>
      helpers.createFilteredMenus(
        configMenus,
        user,
        onlyBrochureAccess,
        onlyAccountPayableAccess,
        onlyPrintArchiveAccess,
      ),
    [
      user,
      onlyBrochureAccess,
      onlyAccountPayableAccess,
      onlyPrintArchiveAccess,
    ],
  );

  const onMenuButtonClick = useCallback(async () => {
    setCollapsed(true);
  }, []);

  const setSaveDialog = useCallback(
    (input: boolean, path?: string) =>
      dispatch(actions.assetBuilder.setSaveDialog({ input, newPath: path })),
    [dispatch],
  );

  return (
    <AntLayout className={styles.layout}>
      <AntLayout>
        {!framed && (
          <AntLayout.Header className={styles.header}>
            <Header
              menus={menus}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          </AntLayout.Header>
        )}
        <AntLayout>
          <AntLayout.Sider
            className={styles.sider}
            width={240}
            theme="light"
            trigger={null}
            collapsible={true}
            collapsedWidth={0}
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
          >
            <div className={styles.flexSiderDiv}>
              <Title collapsed={collapsed} />
              <Menu
                menus={menus}
                setSaveDialog={setSaveDialog}
                onMenuButtonClick={onMenuButtonClick}
              />
              <Patent collapsed={collapsed} />
            </div>
          </AntLayout.Sider>
          <AntLayout.Content
            className={styles.layoutContent}
            onClick={() => setCollapsed(true)}
          >
            <Outlet />
          </AntLayout.Content>
        </AntLayout>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
