import { Progress, notification } from "antd";

export const invoiceRegenerationProgressKey = "invoiceRegenerationProgress";

export const openInvoiceRegenerationProgress = (
  progress: number,
  state: "in-progress" | "failed" | "success" = "in-progress",
) => {
  const isComplete = progress === 100 && state === "success";
  const isFailed = state === "failed";

  notification.open({
    key: invoiceRegenerationProgressKey,
    message: (
      <strong>
        {isFailed ? "Invoice Regeneration Failed" : "Regenerating Invoice"}
      </strong>
    ),
    duration: isComplete || isFailed ? 4 : 0,
    bottom: 50,
    placement: "bottomRight",
    description: (
      <>
        <Progress
          type="line"
          percent={isFailed ? 100 : progress}
          status={isFailed ? "exception" : "success"}
        />
      </>
    ),
  });
};
