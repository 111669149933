import { useState } from "react";
import {
  ToolbarButton,
  ToolbarTable,
} from "shared/components/toolbarTable/ToolbarTable";
import InvoiceFilterDrawer from "../InvoiceDrawer/FilterDrawer";
import { Invoice, InvoiceStatus } from "shared/types/salesEnablement";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { generateInvoiceColumns } from "./Columns";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import EmptyInvoice from "../EmptyInvoice/EmptyInvoice";
import { fields } from "../fields";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import styles from "./Table.module.scss";
import { useInvoice } from "../hooks/useInvoice";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadInvoices } from "../utils";
import { message } from "antd";
import EmailDrawer from "../InvoiceDrawer/EmailDrawer";

export const InvoiceTable = () => {
  const navigateWithSearch = useNavigateWithSearch();
  const { useFetchInvoices, useRegenerateInvoice } = useInvoice();
  const [openFilter, setOpenFilter] = useState(false);
  const [isEmailDrawerVisible, setIsEmailDrawerVisible] = useState(false);
  const [selectedForEmail, setSelectedForEmail] = useState<Invoice[]>([]);
  const { windowInnerHeight } = useWindowSize();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();
  const { data: invoiceData, isLoading } = useFetchInvoices();
  const { mutate: regenerateInvoice, isLoading: isRegenerateLoading } =
    useRegenerateInvoice();

  const { selectedItems, data, originalData } = useDataListURLData<
    keyof Invoice,
    Invoice
  >({
    data: invoiceData,
    isLoading,
    isError: false,
  });

  const openEmailDrawer = () => {
    if (selectedItems.length === 0) {
      message.error("Please select at least one invoice to email.");
      return;
    }
    setSelectedForEmail(selectedItems);
    setIsEmailDrawerVisible(true);
  };

  const closeEmailDrawer = () => {
    setIsEmailDrawerVisible(false);
    setSelectedForEmail([]);
  };

  const generateToolbarContents: (
    record?: Invoice,
  ) => ToolbarButton = record => {
    if (!record) return {};
    return {
      Regenerate: {
        showInContextMenu: true,
        showInInlineMenu: true,
        disabled:
          record.status === InvoiceStatus.COMPLETED || isRegenerateLoading,
        onClick: () => regenerateInvoice(record),
      },
      DownloadPDF: {
        showInContextMenu: true,
        showInInlineMenu: true,
        onClick: () => downloadInvoices(record),
        extraInfo: {
          title: "Download",
          tooltipText: "Download",
          icon: <DownloadOutlined />,
        },
      },
      Email: {
        showInContextMenu: true,
        showInInlineMenu: true,
        onClick: () => {
          setSelectedForEmail([record]);
          setIsEmailDrawerVisible(true);
        },
      },
    };
  };

  const toolbarContents = {
    DownloadPDF: {
      label: "Download",
      disabled: selectedItems.length === 0,
      onClick: () => downloadInvoices(selectedItems),
      extraInfo: {
        title: "Download",
        tooltipText: "Download",
        icon: <DownloadOutlined />,
      },
    },
    Email: {
      disabled: selectedItems.length === 0,
      onClick: openEmailDrawer,
    },
  };

  return (
    <div className={styles.container}>
      <ToolbarTable
        searchValue={globalFilter ?? undefined}
        disableSearch={false}
        toolbarContents={toolbarContents}
        searchPlaceholder="Search"
        titleTooltip="Search Invoices"
        onSearch={setGlobalFilter}
      />

      <DataListTagsURL<keyof Invoice, Invoice>
        data={data}
        originalData={originalData}
        onAddFilterClick={() => setOpenFilter(true)}
      />

      <TableActionsContextProvider<Invoice>
        renderContextActions={record => (
          <ContextTableActions
            getItemsToRender={() => generateToolbarContents(record)}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={() => generateToolbarContents(record)}
            record={record}
          />
        )}
      >
        <DataListURLTable<keyof Invoice, Invoice>
          type="hoverActionsVirtualTable"
          loading={isLoading}
          dataSource={data}
          pagination={false}
          locale={{
            emptyText: <EmptyInvoice />,
          }}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          columns={generateInvoiceColumns(navigateWithSearch)}
          fieldKeys={Object.keys(fields) as (keyof Invoice)[]}
        />
      </TableActionsContextProvider>

      <InvoiceFilterDrawer
        invoiceTableData={data ?? []}
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />

      <EmailDrawer
        data={selectedForEmail}
        isEmailDrawerVisible={isEmailDrawerVisible}
        closeEmailDrawer={closeEmailDrawer}
      />
    </div>
  );
};

export default InvoiceTable;
