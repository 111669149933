import { useIsAdmin } from "./useIsAdmin";
import { useHasSalesEnablementReadAccess } from "./useHasSalesEnablementReadAccess";
import { useIsPrintUser } from "./useIsPrintUser";

export const useHasPrintArchiveAccess = () => {
  const isAdmin = useIsAdmin();
  const hasSalesEnablementReadAccess = useHasSalesEnablementReadAccess();
  const isPrintUser = useIsPrintUser();

  const hasPrintArchiveAccess = isAdmin || isPrintUser;

  const onlyPrintArchiveAccess =
    hasPrintArchiveAccess && !isAdmin && !hasSalesEnablementReadAccess;

  return {
    hasPrintArchiveAccess,
    onlyPrintArchiveAccess,
  };
};
