import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useHasAccountPayableAccess } from "shared/hooks/useHasAccountPayableAccess";
import { useHasPrintArchiveAccess } from "shared/hooks/useHasPrintArchiveAccess";

const featureModules = {
  invoices: "/sales-enablement/invoices",
  printArchive: "/sales-enablement/print-archive",
} as const;

export const SalesEnablementFeatureGuard = () => {
  const location = useLocation();
  const { onlyAccountPayableAccess } = useHasAccountPayableAccess();
  const { onlyPrintArchiveAccess } = useHasPrintArchiveAccess();

  if (
    onlyAccountPayableAccess &&
    !location.pathname.startsWith(featureModules.invoices)
  ) {
    return <Navigate to={featureModules.invoices} replace />;
  }

  if (
    onlyPrintArchiveAccess &&
    !location.pathname.startsWith(featureModules.printArchive)
  ) {
    return <Navigate to={featureModules.printArchive} replace />;
  }

  return <Outlet />;
};
