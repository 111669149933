import { Form, Select, Input, ConfigProvider, Empty, Typography } from "antd";
import styles from "./EmailDrawerForm.module.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useEmailRecipients } from "shared/hooks/adLibrary/marketingMaterials";
import { Rule } from "antd/lib/form";
import { EmailDeliveryResponse } from "shared/types/marketingMaterials";

export const EmailDeliveryForm = ({
  id,
  lastRun,
}: {
  id: string;
  lastRun?: EmailDeliveryResponse;
}) => {
  const { data: defaultRecipients } = useEmailRecipients();
  return (
    <>
      <Form.Item
        className={styles["form-item"]}
        label={`From`}
        name={[id, "from"]}
        labelAlign={`left`}
        rules={[
          {
            required: true,
            message: `This field is required.`,
          },
        ]}
      >
        <Select disabled={true} mode="multiple" />
      </Form.Item>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <div className={styles["empty-state-description"]}>
                <Typography.Text className={styles.title}>
                  No emails found
                </Typography.Text>
                <Typography.Text>
                  You can enter or paste multiple email addresses{" "}
                </Typography.Text>
              </div>
            }
          />
        )}
      >
        <Form.Item
          className={styles["form-item"]}
          label={<div>To:</div>}
          colon={false}
          tooltip={{
            title: "Enter or paste up to 10 email addresses.",
            icon: <InfoCircleOutlined />,
          }}
          name={[id, "to"]}
          labelAlign={`left`}
          rules={[
            {
              required: true,
              message: `This field is required.`,
            },
            {
              validator: (_, value: string[]) => {
                if (!value || value.length === 0) {
                  return Promise.resolve();
                }

                const invalidEmails = value.filter(email => {
                  const match = email.match(
                    /^(\s*[^<]*\s*)?<([\w.\-+]+@[a-zA-Z\d.\-]+\.[a-zA-Z]{2,})>|\b([\w.\-+]+@[a-zA-Z\d.\-]+\.[a-zA-Z]{2,})\b/,
                  );

                  return (
                    !match || email.replace(match[0], "").trim().length > 0
                  );
                });
                if (invalidEmails.length > 0) {
                  return Promise.reject(
                    new Error(
                      "One or more of the addresses entered are invalid.",
                    ),
                  );
                }

                if (value.length > 10) {
                  return Promise.reject(
                    new Error("You can only add up to 10 recipients."),
                  );
                }

                return Promise.resolve();
              },
            },
            ...(lastRun?.status !== "success" && lastRun?.status !== "rejected"
              ? ([
                  {
                    validator: (_, value: string[]) => {
                      if (!value || value.length === 0)
                        return Promise.resolve();
                      const invalidList = value.filter(email =>
                        lastRun?.invalidRecipients?.includes(email),
                      );
                      if (invalidList.length > 0) {
                        return Promise.reject(
                          new Error(
                            `The addresses entered above are invalid and couldn't receive the email. Please confirm if they are correct.`,
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ] as Rule[])
              : []),
          ]}
        >
          <Select
            options={defaultRecipients?.map(v => ({ value: v, label: v }))}
            tokenSeparators={[","]}
            mode="tags"
            placeholder="Please select or add new email"
            showArrow
          />
        </Form.Item>
      </ConfigProvider>
      <Form.Item
        className={styles["form-item"]}
        labelAlign="left"
        label={`Subject`}
        name={[id, `subject`]}
        rules={[
          {
            required: true,
            message: `This field is required.`,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};
