import * as crypto from "crypto-js";
import FontFaceObserver from "fontfaceobserver";
import { isEqual, uniq } from "lodash";
import moment from "moment";
import { User, Permissions } from "redux/auth/auth.slice";
import { dateFormat } from "shared/constants/dataManagement";
import GenericError from "shared/errors/GenericError";
import { IAssetInstance, IWorkfrontProofData } from "shared/types/assetBuilder";
import { APIType, Environment } from "shared/types/configuration";
import { IAccount, IAccountRecord } from "shared/types/accountManagement";
import { IFloatingSelectType } from "shared/types/designStudio";
import { IMenu } from "shared/types/menu";
import { IBrandRecord } from "shared/types/brandManagement";
import {
  SalesEnablementAdminOnlyModules,
  AlexiaModule,
  IndustryType,
  OfferType,
} from "shared/types/shared";
import { IUserSort } from "shared/types/userManagement";
import uuid from "uuid/v4";
import { AccessOptions } from "../shared/constants/userPermissions";
import { ftpSecretKey } from "./constants";
import * as validators from "./validators";
import { QcStatus, IAd } from "shared/types/adLibrary";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { rbac } from "config/rbac";

// This function must be used in async wrapper function.
export const delay = (duration: number): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

export const getRandomInt = (upper: number) => {
  return Math.floor(Math.random() * Math.floor(upper));
};

// password validator
// checklist
//  1. Require numbers
//  2. Require special character
//  3. Require uppercase letters
//  4. Require lowercase letters
//  5. Minimum length
const specialCharacters = "!@#$%^&*()_+~";
const alphabets = "abcdefghijklmnopqrstuvwxyz";
export const generateTemporaryPassword = (): string => {
  return `${[...Array(2)]
    .map(() => {
      const randomIndex = getRandomInt(alphabets.length);

      return alphabets[randomIndex];
    })
    .join("")}${[...Array(2)]
    .map(() => {
      const randomIndex = getRandomInt(alphabets.length);

      return alphabets[randomIndex].toUpperCase();
    })
    .join("")}${[...Array(2)]
    .map(() => {
      const randomIndex = getRandomInt(specialCharacters.length);
      return specialCharacters[randomIndex];
    })
    .join("")}${[...Array(2)]
    .map(() => {
      return getRandomInt(10);
    })
    .join("")}`;
};

export const createFilteredMenus = (
  menus: IMenu[],
  user?: User | null,
  onlyBrochureAccess?: boolean,
  onlyAccountPayableAccess?: boolean,
  onlyPrintArchiveAccess?: boolean,
): IMenu[] => {
  if (user) {
    return getPrivateMenus(
      menus,
      user,
      onlyBrochureAccess,
      onlyAccountPayableAccess,
      onlyPrintArchiveAccess,
    );
  }

  return getPublicMenus(menus);
};

export const getPublicPaths = (menus: IMenu[]) => {
  const publicMenus = getPublicMenus(menus);

  return publicMenus.reduce<Array<string>>((acc, menu) => {
    const subMenuPaths =
      menu.subMenus?.reduce<Array<string>>((innerAcc, subMenu) => {
        if (!subMenu.isPublic || !subMenu.path) return innerAcc;

        return [...innerAcc, subMenu.path];
      }, []) || [];

    return acc
      .concat(menu.isPublic && menu.path ? [menu.path] : [])
      .concat(subMenuPaths);
  }, []);
};

const getPublicMenus = (menus: IMenu[]) => {
  return menus
    .filter(menu => {
      const isValidMenu =
        menu.path || menu.subMenus?.some(subMenu => !!subMenu.path);
      if (!isValidMenu) return false;

      const hasSubMenu = menu.subMenus && menu.subMenus.length > 0;
      if (hasSubMenu) {
        return menu.subMenus?.some(subMenu => subMenu.isPublic) || false;
      }

      return menu.isPublic || false;
    })
    .map(menu => {
      if (menu.subMenus) {
        return {
          ...menu,
          subMenus: menu.subMenus.filter(subMenu => subMenu.isPublic),
        };
      }

      return menu;
    });
};

const getPrivateMenus = (
  menus: IMenu[],
  user: User & Permissions,
  onlyBrochureAccess?: boolean,
  onlyAccountPayableAccess?: boolean,
  onlyPrintArchiveAccess?: boolean,
) => {
  return menus
    .filter((menu: IMenu) => {
      // TODO: rewrite all of this
      // const isExternalUser = false;
      // const isExternalUser =
      //   user?.role === Roles.REVIEWER && user?.group === Groups.EXTERNAL;
      // if (isExternalUser) {
      //   const allowedMenus = [
      //     AlexiaModule.CAMPAIGN_MANAGEMENT,
      //     AlexiaModule.METRICS,
      //   ];
      //   if (allowedMenus.includes(menu.module)) return true;
      //   else return false;
      // }
      // filter out the menu that are not enabled.
      const isAssetExporterEnabled = isFeatureEnabled("ENABLE_ASSET_EXPORTER");
      const excludeAssetExporter =
        menu.module === AlexiaModule.ASSET_EXPORTER && !isAssetExporterEnabled;
      if (excludeAssetExporter) return false;

      const isAdLibraryEnabled = isFeatureEnabled("ENABLE_AD_LIBRARY");
      const excludeAdLibrary =
        menu.module === AlexiaModule.AD_LIBRARY && !isAdLibraryEnabled;
      if (excludeAdLibrary) return false;

      const isDiemEnabled = isFeatureEnabled("ENABLE_DIEM");
      const excludeDiem =
        menu.module === AlexiaModule.INSTANT_EXPERIENCE && !isDiemEnabled;
      if (excludeDiem) return false;

      if (SalesEnablementAdminOnlyModules.includes(menu.module)) {
        return user.permissions?.includes(rbac.manageSalesEnablementAdmin);
      }

      if (menu.module === AlexiaModule.BROCHURE_MANAGEMENT) {
        return user.permissions?.some(permission =>
          [rbac.manageBrochures, rbac.readBrochures, rbac.manageAdmin].includes(
            permission,
          ),
        );
      }

      if (onlyBrochureAccess) return false;

      if (onlyAccountPayableAccess) {
        return menu.module === AlexiaModule.INVOICES;
      }

      if (onlyPrintArchiveAccess) {
        return menu.module === AlexiaModule.PRINT_ARCHIVE;
      }

      if (menu.module === AlexiaModule.INVOICES) {
        return user.permissions?.some(permission =>
          [
            rbac.manageAdmin,
            rbac.manageSalesEnablementAdmin,
            rbac.manageSalesEnablementAP,
          ].includes(permission),
        );
      }

      if (menu.module === AlexiaModule.PRINT_ARCHIVE) {
        return user.permissions?.some(permission =>
          [
            rbac.manageAdmin,
            rbac.manageSalesEnablementAdmin,
            rbac.managePrint,
          ].includes(permission),
        );
      }

      return true;
    })
    .filter(({ hidden }) => !hidden)
    .map(menu => ({
      ...menu,
      ...(menu.subMenus
        ? { subMenus: menu.subMenus.filter(({ hidden }) => !hidden) }
        : {}),
    }))
    .filter(({ subMenus }) => !subMenus || subMenus.length > 0);
};

export const getAlexiaPathFromLocation = (path?: string) => {
  if (!path) return "";

  if (path.startsWith("/asset-builder")) return "/asset-builder";
  if (path.startsWith("/design-studio")) return "/design-studio";
  if (path.startsWith("/ad-library")) return "/ad-library";
  if (path.startsWith("/campaign-management")) return "/campaign-management";
  if (path.startsWith("/campaign-planner")) return "/campaign-planner";
  return path;
};

export const getValidAlexiaModuleFromPath = (path: string) => {
  if (!validators.isDestinationUrlInvalid(path)) {
    throw new GenericError({
      message: "Invalid Path",
    });
  }

  const adminPath = [
    "/user-management",
    "/oem-management",
    "/store-management",
    "/brands-accounts-management",
    "/dashboard-management",
  ];

  if (path === "") return "ASSET_BUILDER";
  else if (adminPath.includes(path)) return "ADMIN";
  return path.split("/")[1].toUpperCase().replace("-", "_");
};

export const getNotificationMessageFromAccess = (
  userAccess: AccessOptions,
  module: string,
  path: string,
) => {
  const returnValue = {
    desc: module,
    redirect: "/asset-builder/orders",
  };

  const LIMITED = () => {
    switch (module) {
      case "Asset Builder":
        return (
          (path.includes("/select") && {
            ...returnValue,
            desc: "Select Page",
          }) ||
          (path.includes("/build") && {
            ...returnValue,
            desc: "Build Page",
          }) ||
          (path.includes("/create-offer/") && {
            ...returnValue,
            desc: "Create Offer Page",
          }) ||
          (path.includes("/edit-offer/") && {
            ...returnValue,
            desc: "Edit Offer Page",
          })
        );
      case "Design Studio":
        return (
          path.includes("/editor") && {
            redirect:
              userAccess === "None"
                ? returnValue.redirect
                : "/design-studio/library/templates",
            desc: "Edit Page",
          }
        );
    }

    return false;
  };

  const VIEW = () => {
    const message = LIMITED();
    if (!message) {
      switch (module) {
        case "Asset Builder":
          return (
            path.includes("/asset-launcher") && {
              ...returnValue,
              desc: "Launcher Page",
            }
          );
        case "Design Studio":
          return (
            (path.includes("/artboards") && {
              redirect:
                userAccess === "None"
                  ? returnValue.redirect
                  : "/design-studio/library/templates",
              desc: "Artboards Page",
            }) ||
            (path.includes("/everything-ads") && {
              redirect:
                userAccess === "None"
                  ? returnValue.redirect
                  : "/everything-ads/ads",
              desc: "Everything Ad Page",
            })
          );
      }

      return false;
    }

    return message;
  };

  const NONE = () => {
    const message = LIMITED() || VIEW();
    if (!message) {
      switch (module) {
        case "Asset Builder":
          return (
            path.includes("/review") && {
              ...returnValue,
              desc: "Review Page",
            }
          );
      }
    }

    return message;
  };

  switch (userAccess) {
    case "None":
      return NONE() || returnValue;
    case "Limited":
      return (
        LIMITED() || {
          desc: "",
          redirect: "",
        }
      );
    case "View":
      return (
        VIEW() || {
          desc: "",
          redirect: "",
        }
      );
    default:
      return returnValue;
  }
};

export const getBase64DataUrl = (
  img: File | Blob | undefined,
  callback?: (imageUrl: ArrayBuffer | string | null, error?: string) => void,
) => {
  return new Promise<string>((resolve, reject) => {
    if (img) {
      const reader = new FileReader();
      reader.onload = () => {
        callback ? callback(reader.result) : resolve(reader.result as string);
      };
      reader.onerror = () => {
        callback
          ? callback(null, reader.error?.message)
          : reject(reader.error?.message);
      };
      reader.readAsDataURL(img);
    }
  });
};

export const getBase64FromUrl = async (imageUrl: string): Promise<string> => {
  const response = await fetch(imageUrl);
  if (!response.ok) throw new Error("Failed to fetch image");

  const blob = await response.blob();

  const [, base64] = (await getBase64DataUrl(blob)).split(",");
  return base64;
};

export const getUriFromUrl = async (url: string): Promise<string> => {
  const response = await fetch(url);
  if (!response.ok) throw new Error("Failed to fetch url content");
  const data = await response.blob();
  return URL.createObjectURL(data);
};

export const generateS3FileUrl = (
  s3BucketName: string,
  filename: string,
  featureName: string,
) => {
  const s3FileUrl = `https://${s3BucketName}.s3.amazonaws.com/${featureName}/uploads/${filename}`;
  return s3FileUrl;
};

export const generateGuidFilename = (originalFilename: string) => {
  const guid = uuid();
  const fileExtension = originalFilename.split(".").slice(-1);
  const newFilename = `${guid}.${fileExtension}`;
  return newFilename;
};

export const host =
  (env: Environment, urls: Record<string, Record<string, string>>) =>
  (
    apiModule:
      | "core"
      | "offer"
      | "legal"
      | "assetbuilder"
      | "assetexporter"
      | "assetgenerator"
      | "webintegration"
      | "metrics"
      | "notification"
      | "campaignmgmt"
      | "adload"
      | "media"
      | "genai"
      | "pdfgenerator"
      | "salesenablement"
      | "base"
      | "brochure"
      | "adlibrary" = "core",
    api: APIType = "av2",
  ) => {
    let port: string;
    switch (apiModule) {
      case "core":
        port = ":5001";
        break;
      case "offer":
        port = ":5002";
        break;
      case "legal":
        port = ":5003";
        break;
      case "assetbuilder":
        port = ":5004";
        break;
      case "assetexporter":
        port = ":5005";
        break;
      case "adlibrary":
        port = ":5006";
        break;
      case "assetgenerator":
        port = ":5007";
        break;
      case "webintegration":
        port = ":5008";
        break;
      case "metrics":
        port = ":5009";
        break;
      case "notification":
        port = ":5010";
        break;
      case "campaignmgmt":
        port = ":5011";
        break;
      case "adload":
        port = ":5012";
        break;
      case "media":
        port = ":5013";
        break;
      case "genai":
        port = ":5014";
        break;
      case "pdfgenerator":
        port = ":5015";
        break;
      case "salesenablement":
        port = ":5016";
        break;
      case "base":
        port = ":5017";
        break;
      case "brochure":
        port = ":3111";
        break;
      default:
        throw new GenericError({
          message: `The parameter, apiModule is not valid: ${apiModule}`,
        });
    }

    const path = api === "av2" ? env + "-" + apiModule : env;

    return `${urls[api][env]}${env === "local" ? port : "/" + path}`;
  };

/**
 * This function will be called whenever textbox content changes and decides whether
 * to show the floating selection view or not. The condition that determines to show
 * the selection view is whenever the user types "{". So when user types "{", the selection
 * view shows up and once user selects one of selection, it inserts "<variable>}" right next
 * to the starting "{". Things to make sure is the position of the cursor. Wherever the cursor
 * is within the textbox, the floating selection view has to be displayed when user types "{".
 *
 * In order to determine if user typed "{", we are going to use 2 facts.
 *  - this getFloatingSelectInfo() is called whenever user change the text.
 *  - The cursor position object(ex. {lineIndex: 0, charIndex: 10}) returned from calling get2DCursorLocation().
 *
 * The "charIndex" in {lineIndex: 0, charIndex: 10}, it is the index of the cursor position right after
 * new character has been typed into the textbox.
 *  - ex) text = "test!" and typed "a" in the beginning of the textbox, text = "atest", the "charIndex" is
 *  equal to 1. "a_test", where the cursor is at "_". So the text we need check is at (charIndex - 1).
 */
export const getFloatingSelectInfo = (
  textbox: fabric.Textbox,
  canvasOuterContainerDOM: HTMLDivElement | null,
  canvasDOM: HTMLCanvasElement | null,
): IFloatingSelectType | null => {
  const { lineIndex, charIndex } = textbox.get2DCursorLocation();

  const { textLines } = textbox || { textLines: undefined };
  const text =
    textLines && lineIndex <= textLines.length - 1
      ? textLines[lineIndex]
      : null;

  const lastCharacter = text && charIndex > 0 ? text[charIndex - 1] : "";

  if (
    !text ||
    !lastCharacter ||
    lastCharacter === "}" ||
    lastCharacter !== "{"
  ) {
    return {
      show: false,
      cursorPosition: { lineIndex: 0, charIndex: 0 },
      rect: {
        width: 0,
        height: 0,
      },
      at: {
        left: 0,
        top: 0,
      },
    };
  }

  // default margins
  let leftMargin = 105;
  let topMargin = 15;

  if (canvasOuterContainerDOM && canvasDOM) {
    const containerRect = canvasOuterContainerDOM.getBoundingClientRect();
    const canvasRect = canvasDOM.getBoundingClientRect();

    leftMargin = canvasRect.left - containerRect.left;
    topMargin = canvasRect.top - containerRect.top;
  }

  const { left, top, height, width } = textbox.getBoundingRect();

  return {
    show: true,
    cursorPosition: { lineIndex, charIndex },
    rect: {
      width,
      height,
    },
    at: {
      left: left + leftMargin, // 15 is the left-margin of the canvas
      top: top + topMargin, // 30 is the top-margin of the canvas + 5 is the padding between the textbox and the select
    },
  };
};

export const convertInstanceOffers = (instance: IAssetInstance) =>
  Object.values(instance.offers).map(({ offerData, offerTypes }) => ({
    offerData,
    offerTypes: (Object.keys(offerTypes) as OfferType[]).filter(
      key => offerTypes[key],
    ),
  }));

export const formatPhoneNumber = (phoneNumberText: string) => {
  const onlyNumbersRegex = /^\d+(\d+)?$/;

  if (!onlyNumbersRegex.test(phoneNumberText)) {
    return "";
  }

  if (phoneNumberText.length !== 10) {
    return phoneNumberText;
  }
  const areaCode = `(${phoneNumberText.substr(0, 3)})`;
  const numberString = `${phoneNumberText.substr(
    3,
    3,
  )}-${phoneNumberText.substr(6, 4)}`;
  const formatedPhoneNumber = `${areaCode} ${numberString}`;

  return formatedPhoneNumber;
};

export const revertPhoneNumber = (formatedPhoneNumber: string) => {
  const symbolRegex = /\s|\(|\)|-/g;
  const phoneNumberRegex = /\(\d{3}\)\s\d{3}-\d{4}/g;

  if (
    !phoneNumberRegex.test(formatedPhoneNumber) ||
    formatedPhoneNumber.length !== 14
  ) {
    return formatedPhoneNumber;
  }

  const revertedPhoneNumber = formatedPhoneNumber.replace(symbolRegex, "");

  return revertedPhoneNumber;
};

export const formatNumberValue = (
  numberString: string,
  isLargePercentage?: boolean,
) => {
  const commaSeparatedNumberRegex = /^(\d+,\d+(,\d+)*)$/g;

  if (commaSeparatedNumberRegex.test(numberString)) {
    return `${numberString}`;
  }

  const parsedFloat = parseFloat(numberString);
  const formattedNumber =
    (parsedFloat <= 1 && parsedFloat > 0) || isLargePercentage
      ? `${(isLargePercentage ? parsedFloat : parsedFloat * 100).toFixed(3)}%`
      : returnMoneyString(parsedFloat);

  return formattedNumber.replace(/(\.)?0+%$/g, "%");
};

export const returnMoneyString = (numberValue: number) => {
  const decimalRegex = /(\.\d{1})$/g;
  let newNumberString = numberValue
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (decimalRegex.test(newNumberString)) {
    newNumberString = `${newNumberString}0`;
  }

  if (newNumberString.split(".").length < 2) {
    return `${newNumberString}`;
  }

  return newNumberString;
};

export const returnEpochTimeString = (value: string) => {
  const parsedDate = Date.parse(value); // returns date at midnight

  if (isNaN(parsedDate)) {
    return value;
  }

  return `${parsedDate + timezoneOffset}`;
};

/**
 * This function takes a string and converts to epoch number.
 * Sometimes, we deal with unix number (which is in millisecond). In this case,
 *  it is responsible for the author to handle the conversion.
 * But in all case, we should save it in seconds.
 *
 * @param value string or undefined
 * @returns number | undefined
 */
export const returnMomentTime = (value?: string) => {
  if (!value) return;

  try {
    const epoch = parseInt(value);

    return moment.unix(epoch / 1000);
  } catch (err) {
    return;
  }
};

export const formatDimensions = (dimensions: {
  width: number | string;
  height: number | string;
}) => `${dimensions.width} x ${dimensions.height}`;

export const formatDateValue = (value?: string | number | null) => {
  const properDateRegex = /^\d{2}\/\d{2}\/\d{4}$/g;
  const improperIntParseRegex = /^\d{1,4}$/g;

  if (!value) {
    return "N/A";
  }

  if (value === "Multiple") return value;

  if (typeof value === "string" && properDateRegex.test(value)) {
    return value;
  }

  if (typeof value === "string" && value.includes(","))
    value = value.replace(/,/g, "");
  const parsedInt = typeof value === "string" ? parseInt(value, 10) : value;
  const dateValue =
    isNaN(parsedInt) || improperIntParseRegex.test(parsedInt.toString())
      ? value
      : parsedInt + timezoneOffset;

  const newMoment = moment(dateValue);
  return newMoment.format(dateFormat);
};

export const convertEpochTimeToFullDateString = (epochTime: number) => {
  const newMoment = moment(epochTime);
  const formattedDate = newMoment.format(dateFormat);
  const time = newMoment.format("hh:mm:ss A");
  return `${formattedDate} ${time}`;
};

export const roundTimeStamp = (time: number) => {
  return Math.round(new Date().getTime() / time) * time;
};

/*
  AV2-2151: This 8 hour offset helps handle convert
  epoch time dates, where clients are in different US timezones
*/
export const timezoneOffset = 28800000;

export const setMenuFilter = (
  numLeftCurlies: number,
  numRightCurlies: number,
  caretIndexRef: React.MutableRefObject<number | null>,
  currIndex: number,
  currValue: string,
  prevValue: string,
  currMenuFilter: string,
  setCurrMenuFilter: (currMenuFilter: string) => void,
) => {
  if (numLeftCurlies > numRightCurlies) {
    caretIndexRef.current = currIndex;

    if (currValue.length > prevValue.length) {
      // we need to add on the last letter that was just added
      if (currValue[caretIndexRef.current - 1] !== "{") {
        setCurrMenuFilter(
          currMenuFilter + currValue[caretIndexRef.current - 1],
        );
      }
    } else {
      // backspace button was hit so we need to take off a letter
      setCurrMenuFilter(currMenuFilter.slice(0, currMenuFilter.length - 1));
    }
  }
};

export const returnUrlWithParameters = (args: {
  baseUrl: string;
  endpoint: string;
  parameters?: any;
}) => {
  const { baseUrl, endpoint, parameters } = args;

  if (!parameters) {
    return `${baseUrl}/${endpoint}`;
  }

  const queryStrings = Object.keys(parameters)
    .map(key => {
      if (!parameters[key]) return "";
      return `${key}=${
        key.toLocaleLowerCase().includes("url")
          ? encodeURIComponent(parameters[key])
          : parameters[key]
      }`;
    })
    .filter(qString => !!qString);

  return `${baseUrl}/${endpoint}?${queryStrings.join("&")}`;
};

export const returnCurrentTime = () => moment(Date.now()).format("hh:mm:ss a");

/**
 * Reorder the item in the specified list from `startIndex` to `endIndex`
 * @param list The original list
 * @param startIndex The start index of the element to reorder
 * @param endIndex The end index where the element will be
 */
export const reorderList = (
  list: any[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const defaultDealerData = (dealer?: IAccount) => {
  const currentDealer = {
    dealer_name: dealer?.dealer_name.trim() || "",
    dealer_code: dealer?.dealer_code || "",
    dealer_oem: dealer?.dealer_oem || "",
    dealer_url: dealer?.dealer_url || "",
    address: dealer?.address || "",
    city: dealer?.city || "",
    state: dealer?.state || "",
    zip: dealer?.zip || "",
    zipCodeList: dealer?.zipCodeList || [],
    phone_number: dealer?.phone_number || "",
    final_price_name: dealer?.final_price_name || "",
    logo_url: dealer?.logo_url.trim() || "",
    new_dealer_name: dealer?.new_dealer_name || "",
    logo_urls_from_S3: dealer?.logo_urls_from_S3 || null,
    web_int_positions: dealer?.web_int_positions || [],
    labels: dealer?.labels || [],
    coopDetails: dealer?.coopDetails || {
      emailOrPortal: "",
      coopSite: "",
      coopEmail: "",
      coopUsername: "",
      coopPassword: "",
      coopDealerCode: "",
      coopLoginLocked: false,
    },
    details: dealer?.details || {
      facebook: {
        fbCatalogId: "",
        fbAccountId: "",
        fbPageId: "",
        fbPixelId: "",
        fbInstagramId: "",
      },
    },
    created_at: dealer?.created_at || 0,
    updated_at: dealer?.updated_at || 0,
    enabled: dealer?.enabled ?? false,
  };
  return currentDealer;
};

export const dealerToDealerRecordData = (
  dealer?: IAccount | null | undefined,
) => {
  const {
    dealer_name: dealerName = "",
    dealer_code: dealerCode = "",
    dealer_oem: dealerOem = "",
    dealer_url: dealerUrl = "",
    logo_url: logoUrl = "",
    address: dealerAddress = "",
    city: dealerCity = "",
    state: dealerUsState = "",
    zip: dealerZip = "",
    zipCodeList: dealerZipCodeList = [],
    phone_number: dealerPhoneNumber = "",
    final_price_name: dealerFinalPriceName = "",
    logo_urls_from_S3: logoUrlsFromS3JSON = "",
    web_int_positions: webIntPositions = [],
    labels = [],
    coopDetails = {
      emailOrPortal: "",
      coopSite: "",
      coopEmail: "",
      coopUsername: "",
      coopPassword: "",
      coopDealerCode: "",
      coopLoginLocked: false,
    },
    details: {
      facebook: {
        fbPageId = "",
        fbAccountId = "",
        fbPixelId = "",
        fbCatalogId = "",
        fbInstagramId = "",
      } = {},
      google: { adAccountCID = "" } = {},
      beeswax: { advertiserId = undefined } = {},
    } = {},
    enabled = false,
  } = dealer || {};

  const logoUrlsFromS3 = logoUrlsFromS3JSON
    ? JSON.parse(logoUrlsFromS3JSON)
    : {
        horizontalImagesFromS3: [],
        verticalImagesFromS3: [],
        squareImagesFromS3: [],
        horizontalEventImagesFromS3: [],
        verticalEventImagesFromS3: [],
        squareEventImagesFromS3: [],
      };

  return {
    key: 0,
    dealerName,
    dealerCode,
    dealerOem,
    dealerUrl,
    logoUrl,
    newDealerName: dealerName,
    dealerAddress,
    dealerCity,
    dealerUsState,
    dealerZip,
    dealerZipCodeList,
    dealerPhoneNumber,
    dealerFinalPriceName,
    logoUrlsFromS3,
    webIntPositions,
    launchLabel: "",
    labels,
    coopDetails,
    details: {
      facebook: {
        fbPageId,
        fbAccountId,
        fbPixelId,
        fbCatalogId,
        fbInstagramId,
      },
      google: {
        adAccountCID,
      },
      beeswax: {
        advertiserId,
      },
    },
    enabled,
  };
};

export const customImageDataCheck = (
  customImageCheck: boolean,
  offerAnalyticsData: string,
) => {
  return customImageCheck ? offerAnalyticsData : "";
};

/**
 * @deprecated use `compareStringBy` instead
 */
export const compareString = (a?: string, b?: string) => {
  return (a ?? "").localeCompare(b ?? "");
};

export const getQcStatus = (record: IAd, isValid?: boolean) => {
  return isValid ? record.qcStatus : QcStatus.ERROR;
};

export const checkArrayIncludes = (arr: string[] = [], value?: string) => {
  if (!value) {
    return false;
  }
  if (value === QcStatus.ERROR && arr.includes(QcStatus.ERROR)) {
    return true;
  }
  return arr
    .map(str => str.toLocaleLowerCase())
    .includes(value.toLocaleLowerCase());
};

export const checkIntersectionExists = (
  arr1: string[] = [],
  arr2: string[] = [],
) => arr1.some(item => arr2.includes(item));

/**
 * @deprecated use onFilterBy instead
 */
export const checkFilterMatch = (value: string, input?: string) => {
  return input?.toString().toLowerCase().includes(value.toLowerCase()) || false;
};

export const checkDateMatch = (value: string, target: number) => {
  if (!value) return true;
  const dateFrom = value.split(" ")[0];
  const dateTo = value.split(" ")[1];
  const oneday = 3600 * 1000 * 24;
  return parseInt(dateFrom) < target && target < parseInt(dateTo) + oneday;
};

export const isFeatureEnabled = (key: string, defaultValue?: boolean) => {
  if (!process.env[`REACT_APP_AV2_${key}`] && defaultValue !== undefined)
    return defaultValue;
  return process.env[`REACT_APP_AV2_${key}`] === "true";
};

export const isRawEnvVarEquals = (key: string, target: string) =>
  process.env[`${key}`] === target;

export const isEnvVarEquals = (key: string, target: string) =>
  process.env[`REACT_APP_AV2_${key}`] === target;

export const isPharma = isEnvVarEquals("INDUSTRY", "pharma");
export const brandsAccountsEnabled = isFeatureEnabled(
  "ENABLE_BRANDS_ACCOUNTS",
  true,
);
export const isAuto = isEnvVarEquals("INDUSTRY", "auto");
export const adEngineV2Enabled = isFeatureEnabled("ENABLE_AD_ENGINE_V2", false);

export const getEnvVar = <T extends string>(key: string) =>
  process.env[`REACT_APP_AV2_${key}`] as T;

/**
 * For comma-separated env var values only.
 * Returns true if target is found in the comma-separated values.
 * @param key env var name
 * @param target value to check for
 * @returns boolean
 */
export const envVarCsvContains = (key: string, target: string): boolean => {
  const val = process.env[`REACT_APP_AV2_${key}`];
  return (val && val.split(",").includes(target)) || false;
};

export const searchNestedObject = <T>(
  obj: T,
  searchValue: string,
  caseSensitive = false,
) => {
  if (!obj) return false;
  const matchFound = Object.values(obj).some(innerValue => {
    if (!innerValue) return false;
    if (typeof innerValue === "object") {
      return searchNestedObject(innerValue, searchValue);
    }
    const regex = new RegExp(searchValue, caseSensitive ? "" : "i");
    return regex.test(innerValue?.toString());
  });
  return matchFound;
};

export const titleCase = (text?: string) =>
  (text || "")
    .split("_")
    .join(" ")
    .replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

export const industryType = (industry: IndustryType) => {
  const industryEnv = process.env[`REACT_APP_AV2_INDUSTRY`] || "auto";
  return industryEnv === industry;
};

export const cleanNameStringForComparison = (nameString: string) => {
  const noSpaceName = nameString.replace(/\s/g, "");
  const lowerCaseName = noSpaceName.toLowerCase();
  return lowerCaseName;
};

export const getCurrentMoment = () => {
  const dateFormat = moment.localeData().longDateFormat("L");
  return moment(moment().format(dateFormat));
};

export const getProofData = (
  proofArr: IWorkfrontProofData[],
  selDocId: string,
) => {
  try {
    for (const proof of proofArr) {
      const parsedProof = JSON.parse(proof.versions);
      const keys = Object.keys(parsedProof);
      for (const key of keys) {
        if (!Array.isArray(parsedProof[key])) continue;
        if (!parsedProof[key].length) continue;
        const { documentID } = parsedProof[key][
          parsedProof[key].length - 1
        ] || { documentID: "" };
        if (documentID === selDocId) {
          return {
            key,
            proof,
          };
        }
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const parseSelectedProofs = (selectedProofToUpdate: string) => {
  try {
    const parsedSelectedArr = JSON.parse(selectedProofToUpdate);
    return parsedSelectedArr;
  } catch (err) {
    return {};
  }
};

export const updatePathUrl = (pathname: string, screen: string) => {
  return pathname.replace(/([^\/]+$)/, screen); // replaces everything after the last forward slash
};

export const getUniqValues = <T>(
  list: T[],
  selector: (listItem: T) => string[] | undefined,
): string[] => {
  return list.reduce(
    (prevList, currentItem) =>
      uniq([...prevList, ...(selector(currentItem) || [])]),
    [] as string[],
  );
};

export const addOrRemove = (target: string, arr: string[]) => {
  return arr.includes(target)
    ? arr.filter(value => value !== target)
    : [...arr, target];
};

export const pathnameToAlexiaModule = (
  pathname: string,
): AlexiaModule | null => {
  switch (pathname) {
    case "/everythingads":
      return AlexiaModule.INSTANT_EXPERIENCE;
  }

  return null;
};

export const removeSpaces = (word: string) => {
  return word.replace(/\s/g, "");
};

export const keys = <T extends string>(obj: any): T[] => {
  if (typeof obj !== "object") return [];

  return Object.keys(obj) as T[];
};

export const encryptPassword = (password: string) => {
  const encrypted = crypto.AES.encrypt(password, ftpSecretKey).toString();
  return encrypted;
};

export const filterEnableBy = (
  row: IAccountRecord | IUserSort | IBrandRecord,
  isActive: boolean,
) => {
  return (row.enabled ?? false) === isActive;
};

const parseJellybeanImgUrlAttributes = (url: string) =>
  url.substring(url.lastIndexOf("/") + 1).split("_");

export const isCorrectJellybeanUrlForOffer = (
  url: string,
  offerData: { year: string; make: string; model: string; trim?: string },
) => {
  const parsedUrl = parseJellybeanImgUrlAttributes(url);

  return (
    parsedUrl.includes(offerData.year) &&
    parsedUrl.includes(offerData.make) &&
    parsedUrl.includes(offerData.model) &&
    (!offerData?.trim || parsedUrl.includes(offerData.trim))
  );
};

export const loadFont = async (fontFamily: string) => {
  try {
    const fontFace = new FontFaceObserver(fontFamily);
    await fontFace.load();
    return fontFace;
  } catch (err) {
    return undefined;
  }
};

// converts `Learn More` to `learn-more`
export const formatOption = (value: string) => {
  return value?.toLowerCase().replaceAll(" ", "-");
};

export const isDynamicAd = (adFormat: AdType | null | undefined) => {
  if (!adFormat) return false;

  return [AdType.AIA, AdType.DPA, AdType.FTA].includes(adFormat);
};

/**
 * Performs a deep equality check between two objects using a custom recursive approach.
 * This method was created due to instability issues with lodash's isEqual when dealing with objects with many nested layers.
 * It handles deep comparisons manually to avoid potential pitfalls in lodash's implementation, especially in complex or highly nested objects.
 *
 * @param {any} a - The first object to compare.
 * @param {any} b - The second object to compare.
 * @returns {boolean} - Returns true if objects are deeply equal, otherwise false.
 */
export const isDeepEqual = (a: any, b: any): boolean => {
  if (!isEqual(a, b)) {
    if (
      typeof a === "object" &&
      typeof b === "object" &&
      a !== null &&
      b !== null
    ) {
      const keys = [...new Set([...Object.keys(a), ...Object.keys(b)])];
      return keys.every(key => isDeepEqual(a[key], b[key]));
    } else {
      return false;
    }
  }
  return true;
};
